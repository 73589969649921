@use 'partials' as *;

.componentStyle {
  text-align: center;
}

.errorCode {
  font-size: size(50);
}

.errorText {
  font-size: size(18);
}

// PC
@include media-min(pc) {
  .errorCode {
    font-size: size(72);
  }

  .errorText {
    font-size: size(24);
  }
}
